var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { library, } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
library.add(fab, fal);
export function FAIcon(props) {
    if (!props.icon)
        return null;
    return (React.createElement(FontAwesomeIcon, __assign({}, props, { icon: [props.iconPrefix || "fal", props.icon] })));
}
export var FAIconStyles = function (styles) { return ({
    "svg.svg-inline--fa&": styles,
}); };
