import { useCallback, useEffect } from "react";
export function useClickOutside(ref, callback) {
    var handleClickOutside = useCallback(function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
            callback();
        }
    }, [callback, ref]);
    useEffect(function () {
        document.addEventListener("click", handleClickOutside);
        return function () {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [handleClickOutside]);
}
